import './bootstrap';
import '../css/app.scss';
import "vue-toastification/dist/index.css";

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist';
import { i18nVue } from 'laravel-vue-i18n';
import { createPinia } from 'pinia';
import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';
import Toast, { POSITION } from "vue-toastification";
import VueSidePanel from 'vue3-side-panel';
import 'vue3-side-panel/dist/vue3-side-panel.css';
import Popper from "vue3-popper";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import CKEditor from '@ckeditor/ckeditor5-vue';
import piniaPluginPersistedState from "pinia-plugin-persistedstate";
import.meta.glob('../images/**/*', { eager: true });

const toastOptions = {
    position: POSITION.BOTTOM_RIGHT,
    timeout: 3000
};

/* made asset() function available in .vue template */
import Vapor from 'laravel-vapor'
Vapor.withBaseAssetUrl(import.meta.env.VITE_VAPOR_ASSET_URL)
window.Vapor = Vapor

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import {
    faAngleDown,
    faAngleUp,
    faArrowLeft,
    faArrowRight,
    faArrowUp,
    faArrowPointer,
    faArrowsRotate,
    faCar,
    faChevronRight,
    faChevronLeft,
    faChevronDown,
    faChevronUp,
    faBan,
    faBell,
    faBicycle,
    faBuilding,
    faBullhorn,
    faCalendar,
    faCalendarCheck,
    faCalendarDays,
    faCalendarPlus,
    faCalendarXmark,
    faCheck,
    faCircle,
    faCircleCheck,
    faCircleQuestion,
    faCircleXmark,
    faCirclePause,
    faCircleInfo,
    faCircleExclamation,
    faClock,
    faComment,
    faComments,
    faCrown,
    faEllipsisVertical,
    faEnvelope,
    faEye,
    faEyeSlash,
    faFile,
    faFileCsv,
    faFileExcel,
    faFilePdf,
    faFlag,
    faFloppyDisk,
    faFolderOpen,
    faForwardStep,
    faGauge,
    faGear,
    faGlobe,
    faGraduationCap,
    faHome,
    faHandSparkles,
    faHourglass,
    faHourglassEnd,
    faHourglassStart,
    faLock,
    faLocationDot,
    faMagnifyingGlass,
    faMobile,
    faMoneyBill,
    faMotorcycle,
    faPaperPlane,
    faPause,
    faPenToSquare,
    faPeopleGroup,
    faPersonWalkingArrowRight,
    faPhone,
    faMapPin,
    faPlus,
    faPowerOff,
    faBed,
    faBookOpenReader,
    faRobot,
    faRotate,
    faSackDollar,
    faShuffle,
    faSignal,
    faStar,
    faSuitcase,
    faTrash,
    faTriangleExclamation,
    faTv,
    faUndo,
    faUnlock,
    faUser,
    faUserDoctor,
    faUserMinus,
    faUserSlash,
    faXmark,
    faLightbulb,
    faQuestion,
    faCopy,
    faEllipsis,
    faUpload,
    faList,
    faEarthEurope,
    faMoon,
    faUtensils,
    faCube,
    faFileCircleQuestion,
    faFileMedical,
    faLink,
    faTags,
    faUsers,
    faEuroSign,
    faCalculator,
    faDownload
} from '@fortawesome/free-solid-svg-icons'

import {
    faApple,
    faGoogle,
    faMicrosoft,
    faWindows,
    faAws
} from '@fortawesome/free-brands-svg-icons'

/* add icons to the library */
library.add([
    faAngleDown,
    faAngleUp,
    faArrowLeft,
    faArrowRight,
    faArrowUp,
    faArrowsRotate,
    faCar,
    faChevronRight,
    faChevronLeft,
    faChevronDown,
    faChevronUp,
    faBan,
    faBell,
    faBicycle,
    faBuilding,
    faBullhorn,
    faCalendar,
    faCalendarCheck,
    faCircleQuestion,
    faCalendarDays,
    faCalendarPlus,
    faCalendarXmark,
    faCheck,
    faCircle,
    faCircleExclamation,
    faCircleCheck,
    faCircleInfo,
    faCirclePause,
    faCircleXmark,
    faClock,
    faComment,
    faComments,
    faCrown,
    faEllipsisVertical,
    faEnvelope,
    faEye,
    faEyeSlash,
    faFile,
    faFileCsv,
    faFileExcel,
    faFilePdf,
    faFlag,
    faFloppyDisk,
    faFolderOpen,
    faForwardStep,
    faGauge,
    faGear,
    faGlobe,
    faGraduationCap,
    faHandSparkles,
    faHome,
    faHourglass,
    faHourglassEnd,
    faHourglassStart,
    faLock,
    faLocationDot,
    faMagnifyingGlass,
    faMobile,
    faMoneyBill,
    faMotorcycle,
    faPaperPlane,
    faPause,
    faPenToSquare,
    faPeopleGroup,
    faPersonWalkingArrowRight,
    faPhone,
    faMapPin,
    faPlus,
    faPowerOff,
    faBed,
    faBookOpenReader,
    faRobot,
    faRotate,
    faSackDollar,
    faShuffle,
    faSignal,
    faStar,
    faSuitcase,
    faTrash,
    faTriangleExclamation,
    faTv,
    faUser,
    faUndo,
    faUnlock,
    faUserDoctor,
    faUserMinus,
    faUserSlash,
    faWindows,
    faXmark,
    faLightbulb,
    faArrowPointer,
    faQuestion,
    faMicrosoft,
    faApple,
    faGoogle,
    faCopy,
    faEllipsis,
    faUpload,
    faList,
    faEarthEurope,
    faMoon,
    faUtensils,
    faCube,
    faFileCircleQuestion,
    faFileMedical,
    faLink,
    faTags,
    faHandSparkles,
    faUsers,
    faAws,
    faEuroSign,
    faCalculator,
    faDownload
])

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';
const pinia = createPinia()
pinia.use(piniaPluginPersistedState)

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })
            .use(pinia)
            .use(plugin)
            .use(VueSidePanel)
            .use(PrimeVue,  {
                theme: {
                    preset: Aura
                }
            })
            .use(Toast, toastOptions)
            .use(ZiggyVue, Ziggy)
            .use(CKEditor)
            .use(i18nVue, {
                lang: 'fr',
                resolve: (lang) => import(`../lang/${lang}.json`)
            })
            .mixin({
                methods: { asset: window.Vapor.asset }
            })
            .component('font-awesome-icon', FontAwesomeIcon)
            .component("Popper", Popper)
            .mount(el);
    },
    progress: {
        color: '#4B5563',
    },
});


